
import { Vue } from "vue-property-decorator";
import _ from "lodash";

import { AccountService } from "@/core/services";
import { routeStack } from "./core/services/routeStack.service";

export default class AppVue extends Vue {
  currentTitle: string = "";
  trackEvent(event: string, data: any) {
    this.$appInsights.trackEvent({ name: event, properties: data });
    if (data.category) {
      this.$gtag.event(event, {
        event_category: data.category,
        event_label: data.label,
        value: data.value,
      });
    }
  }

  roleToName(role: string) {
    switch (role) {
      case "admin":
        return "CIS Administrator";
      case "AccountAdmin":
        return "Account Administrator";
      case "user":
        return "User";
      case "ApiUser":
        return "CIS API User";
      case "online":
        return "CIS Online";
      case "":
        return "No Role";
      default:
        return role;
    }
  }

  updateTitle(title: string) {
    this.currentTitle = title;
    document.title = `${title} - CIS Online`;
  }

  notify(title: string, message: string, type: any, html: boolean = false) {
    this.$notify({
      title,
      message,
      type,
      dangerouslyUseHTMLString: html,
    });
  }

  isInRole(roleName: string) {
    return AccountService.isInRole(roleName);
  }

  get forgetProjectMessage() {
    return (
      "Forgetting a project will remove it from all search results and alerts." +
      "<br /><br/> You can review forgotten projects from your <a href='/settings/forgotten-projects'>account page</a>."
    );
  }

  get maxForgottenProjectsReachedMessage() {
    return (
      "You have reached the maximum number of forgotten projects on your account." +
      "<br /><br/>Review and remove older forgotten projects from your <a href='/settings/forgotten-projects'>account page</a>."
    );
  }

  get currentUserId() {
    const p = this.$auth.getPayload()["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
    return parseInt(p, 10);
  }

  get isExportEnabled() {
    return AccountService.getSubscriptionType().IsExportEnabled;
  }
  get isAdmin() {
    return AccountService.isAdmin();
  }
  get isUserflowEnabled() {
    return process.env.VUE_APP_USERFLOW_ENABLED === "true";
  }
  get isZohoChatEnabled() {
    return process.env.VUE_APP_ZOHO_CHAT_ENABLED === "true";
  }
  get userflowToken() {
    return process.env.VUE_APP_USERFLOW;
  }

  get isRecallCalendarVisible() {
    return process.env.VUE_APP_SHOW_ICAL;
  }

  get environment() {
    return process.env.VUE_APP_ENVIRONMENT;
  }

  get isDevEnv() {
    return this.environment === "Dev";
  }

  get isTestEnv() {
    return this.environment === "Test";
  }

  get isProdEnv() {
    return this.environment === "Production";
  }
}
