









import { Component } from "vue-property-decorator";
import { AccountService, AuthTokenService } from "@/core/services";
import AppVue from "@/AppVue.vue";
import { Vue } from "vue-property-decorator";
import ImpersonationBarCpt from "./modules/ImpersonationBarCpt.vue";
import { zohoManager } from "./zoho/zoho";

@Component({
  components: {
    ImpersonationBarCpt: () => Promise.resolve(ImpersonationBarCpt),
  },
})
export default class App extends Vue {
  get layout() {
    return (this.$route.meta.layout || "default") + "-layout";
  }
  created() {}
  mounted() {
    const email = AuthTokenService.currentTokenValue(
      "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
    );
    if (!this.$isMobile()) {
      zohoManager.init(email);
    }
  }
  destroyed() {}
}
